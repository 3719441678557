<template>
  <div class="app-box">
    <div class="headerBg cearcone">
      <div class="top">
        <div class="left">
          <img v-if="infoArr.avatar_img" :src="infoArr.avatar_img" @click="goRouter('/MySetUp')" alt="">
          <img v-else src="../../assets/img/csUser.jpg" @click="goRouter('/MySetUp')" alt="">
        </div>
         <div class="right">
          <div class="titleg" >{{infoArr.nickname}}</div>
          <div class="tmUser">{{infoArr.role_name}}
            <span v-if="infoArr.id_card == null||infoArr.id_card =='' && infoArr.id_card == null||infoArr.id_card ==''" class="authentication authentication1">未认证</span>
            <span v-else class="authentication authentication1">已认证</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
    <div class="ulList">
      <div class="li" @click="goRouter('/order')">
          签约订单
          <i class="iconfont icon-right rightIcom" ></i>
          <span class="fr mark" v-if="infoArr.order_num">{{infoArr.order_num}}</span>
      </div>
       <div class="li" @click="goRouter('/MySetUp')">
          个人信息
          <i class="iconfont icon-right rightIcom"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data(){
    return{
      
    }
  },
  computed:{
    ...mapState(['infoArr'])
  },
  mounted(){
    this.info();
  },
  methods:{
    info(){
       this.$store.dispatch('info')
    },
  }
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #efeff4;
  box-sizing: border-box;
}
.cearcone {
  padding: 15px 10px;
  box-sizing: border-box;
  background: url(../../../src/assets/img/personal_head_bg.jpg) no-repeat;
  background-size: 100% 100%;
  padding-bottom: 50px;
}
.headerBg {
  width: 100%;
  overflow: hidden;

  /* background: red; */
  background-size: 100% 100%;
}
.headerBg .top {
  overflow: hidden;
  position: relative;
}
.headerBg .left {
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  float: left;
}
.headerBg .left img {
  width: 100%;
}
.headerBg .right {
  margin-left: 50px;
  color: #fff;
}
.authentication {
  font-size: 10px;
  background: #6782f2ff;
  padding: 3px 4px;
  border-radius: 3px;
  margin-left: 5px;
}
.ulList{
  width: 94%;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  margin-top: -30px;
}
.ulList .li{
  padding: 13px 0;
  border-bottom: 1px solid #F3F3F5FF;
  margin: 0 15px;
}
.ulList .li .rightIcom{
  float: right;
  font-size: 20px;
  color: #9395A6FF;
}
.ulList .li .fr{
  float: right;
}
.ulList .li .mark{
 background: #0E8AFFFF;
 color: #fff;
 border-radius: 50%;
 display: inline-block;
 width: 16px;
 height: 16px;
 text-align: center;
 line-height: .4rem;
 margin-top: 3px;
 padding: 1px;
 font-size: .33rem;
}
.right .titleg{
 margin-bottom: .1rem;
 font-size: .4rem;
}
.right .tmUser{
 font-size: .4rem;
}
.ulList .li{
  font-size: .4rem;
}
</style>